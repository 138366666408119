html, body {
  margin: 0;
  font-family: 'Sriracha'!important;
  font-family: -apple-system, BlinkMacSystemFont,'Sriracha', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* latin */
@font-face {
  font-family: 'Sriracha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./themes/fonts/Sriracha.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
