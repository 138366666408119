.profil {
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom:hover {
  -ms-transform: scale(1.07); /* IE 9 */
  -webkit-transform: scale(1.07); /* Safari 3-8 */
  transform: scale(1.07);
}
.resume {
  box-shadow: 10px 10px 10px #bebebe;
}
.resume:hover {
  box-shadow: 10px 10px 10px #b1b1b1;
}
.certificate-card {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.certificate-card:hover {
  transform: translateY(-5px);
}

.certificate-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.certificate-card:hover .certificate-image {
  transform: scale(1.05);
}

.project-card {
  transition: transform 0.2s ease-in-out;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-image-wrapper {
  position: relative;
  overflow: hidden;
}

.project-image {
  transition: transform 0.3s ease;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.project-card:hover .project-image {
  transform: scale(1.1);
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-card {
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transition: all 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.icon-wrapper {
  background: rgb(188, 218, 254);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-icon {
  color: #3b82f6;
}

.contact-content h3 {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

.contact-content p {
  color: #9ca3af;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .contact-card {
    padding: 1rem;
  }
}

/* Form input placeholder styles */
.form-control::placeholder {
  color: inherit;
  opacity: 0.7;
}

/* Form input focus styles */
.form-control:focus {
  background: inherit;
  color: inherit;
  box-shadow: none;
  border-color: inherit;
}
