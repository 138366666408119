.timeline-with-icons {
    border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
    text-align: left;
  }
  
  .timeline-with-icons .timeline-item {
    position: relative;
  }
  
  .timeline-with-icons .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
  }
  
  .timeline-with-icons .timeline-icon {
    position: absolute;
    left: -48px;
    background-color: hsl(217, 88.2%, 90%);
    color: hsl(217, 88.8%, 35.1%);
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  